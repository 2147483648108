<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="username">
          {{ $t('auth---Welcome, {username}!', { username: username }) }}
        </template>
        <template v-else>{{ $t('auth---Welcome!') }}</template>
      </h2>

      <p class="b-text _note _center">
        {{ translate('auth---Create new password') }}
      </p>

      <form class="b-form _code" @submit.prevent="sendPassword">
        <div class="b-row _space_above">
          <input
            class="b-input"
            :placeholder="$t('auth---Enter password')"
            autofocus
            id="password"
            v-model="password"
            :type="!showPassword ? 'password' : 'text'"
            name="password"
          />
          <button
            class="b-icon _input-button _eye"
            type="button"
            @click="showPassword = !showPassword"
          ></button>
        </div>

        <div class="b-row">
          <input
            class="b-input"
            :class="{ _error: password2 && !!errors.password }"
            :placeholder="$t('auth---Repeat password')"
            id="password2"
            v-model="password2"
            :type="!showPassword ? 'password' : 'text'"
            name="password2"
          />
          <div
            class="b-error"
            v-if="password2 && errors.password"
            v-html="errors.password"
          ></div>
        </div>

        <div class="b-row _space_above">
          <button class="b-button _full-width" type="submit">
            {{ $t('auth---Save') }}
          </button>
        </div>
      </form>
    </div>
  </Outer>
</template>

<script>
import Outer from '../components/Outer.vue';
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    Outer,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      password2: null,
    };
  },
  mounted() {
    document.title = this.$i18n.t('auth---Set password');
  },
  watch: {
    password: function () {
      this.compare();
    },
    password2: function () {
      this.compare();
    },
  },
  computed: {
    ...mapState({
      username: (state) => state.username,
      errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions(['setError', 'setStep', 'setPassword']),
    compare() {
      if (this.password !== this.password2) {
        this.setError({
          errorId: 'password',
          message: this.$i18n.t("auth---Password mismatch"),
        });
      } else {
        this.setError({
          errorId: 'password',
          message: null,
        });
      }
    },
    sendPassword() {
      this.setPassword(this.password);
      this.setStep(4);
      this.$router.push({ name: 'auth-set-login' });
    },
  },
};
</script>
